import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configs, {getParameterByName} from './Constants';
import { SvgIcon } from '@mui/material';


const token=getParameterByName('token');
const merchantCode=getParameterByName('merchantCode');
console.log(configs);
const root = ReactDOM.createRoot(document.getElementById('root'));
const getRootRender = ()=>{
	if(configs && configs.authUrl){
		if(merchantCode || token || sessionStorage.getItem("token")){
			return <App />;
		}
		else if(!merchantCode && (!token || !sessionStorage.getItem("token"))){
			 window.location.href =configs.authUrl+"/account/login?redirect="+window.location.origin;
			 return null;
		}

	} else {
		document.write("Configuration missing, check with admin.")
	}
}
root.render(
  <div>
    {
    	getRootRender()
    	}
  </div>
);
