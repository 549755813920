import React, { useState } from "react";
import CardMedia from '@mui/material/CardMedia';
import { Card, CardActionArea, Typography, CardHeader, CardContent } from "@mui/material";

function Help() {
    const [playing, setPlaying] = useState(false);

    const handleVideoClick = () => {
        setPlaying(!playing);
    };

    return (
        <div>
 <div style={{ height: "94vh", overflow: "auto" }} id="cont">
            <div id="videoContainer">
                <Card raised={true} id="card">
                    <CardHeader
                        title={"Introduction"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/intro-and-overview.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Introduction And Overview
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>       
                  <Card raised={true} id="card">
                    <CardHeader
                        title={"Category and Varieties"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Two-1-categories-and-variety-point.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                How to Create Categories And Varieties
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>    
                   <Card raised={true} id="card">
                    <CardHeader
                        title={"Addon Category"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Two-2-create-add-on-categories-and-item.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Creating Addons Category And it Items
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card> 
                <Card raised={true} id="card">
                    <CardHeader
                        title={"Creating Items"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Three-creating-items-with-varieties-and-add-ons.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Creating Items / Products
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>      
                 <Card raised={true} id="card">
                    <CardHeader
                        title={"Manage Order"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Five-Manage-orders.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                               How to Manage Order
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>  
                
                   <Card raised={true} id="card">
                    <CardHeader
                        title={"Staff Login"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Four-1-how-to-login-to-pos-using-tablet-and-mobile.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Responsive Member Login
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>   
                
                
                <Card raised={true} id="card">
                    <CardHeader
                        title={"Dine In Order"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Four-2-how-to-place-order-for-dine-in-using-admin.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                How to Order For Dine In
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card> 
                
                
                <Card raised={true} id="card">
                    <CardHeader
                        title={"Delivery Order"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Four-3-how-to-place-order-for-delivery.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                How to Order For Delivery
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>    
                
                 <Card raised={true} id="card">
                    <CardHeader
                        title={"Reports"}
                    />

                    <CardActionArea onClick={handleVideoClick}>
                        <CardMedia
                            component='video'
                            src={"https://digisign24.s3.ap-south-1.amazonaws.com/common/pos-help/Six-Reports.mp4"}
                            controls={playing}
                            autoPlay={playing}
                            loop={playing}
                            muted={playing}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                             Reports Section with Download Feature
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
            </div>
        </div>
    );
}

export default Help;
